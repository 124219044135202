<template>
	<section id="newsletterNewPage">
		<router-view></router-view>
	</section>
</template>

<script>
export default {
  beforeRouteLeave(to, from, next) {
		this.$store.commit('newsletter/resetNewNewsletterItems')
		next()
  }
}
</script>
<style lang="scss">
.newsletter-steps {
	padding: 0;
	margin: 0 auto 30px;
	list-style-type: none;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: 450px;
	li {
		justify-self: center;
		font-weight: bold;
		cursor: pointer;
		&:first-child {
			span::after {
				content: '';
				width: 300px;
				height: 2px;
				background-color: #E04E39;
				position: absolute;
				left: 0;
				top: 20px;
				z-index: -1;
			}
		}
		&.router-link-active {
			span {
				background-color: #E04E39;
				color: white;
			}
		}
		span {
			position: relative;
			display: block;
			margin: 0 auto 10px;
			width: 44px;
			height: 44px;
			border-radius: 50%;
			color: #E04E39;
			border: 1px solid #E04E39;
			font-weight: bold;;
			text-align: center;
			line-height: 44px;
			background-color: white;
		}
		div {

		}
	}
}
</style>